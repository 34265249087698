import './home.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import { getState1NTWItem, getReportKey, getSubmitted, NTWReportActions } from '@whanau/domains';

import { KFormText } from '../components/inputs';
// import Child from '../Child Hub Collobarative Kia Kotahi te Tamaiti FINAL-02.png';
import { Section } from '../components/section';

// import logo1 from '../MDHB Logo Clr 4 Proc.png';

export const State1 = () => {
    const item = useSelector(getState1NTWItem);
    const key = useSelector(getReportKey);
    const submitted = useSelector(getSubmitted);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const page1Title = 'Whānau Ora Covid19 Dashboard';
    const sectionTitle = 'Sharing the collaboration of our network';
    const sectionSubtitle =
        "The Whānau Ora Covid19 Dashboard is linked to the 'Fight for your whakapapa' campaign. It aims to:";
    const descriptionList = [
        {
            id: '1',
            item:
                'Provide our network with regional insights on how Whānau Ora supports whānau Māori and Maori communities alike, deal with the impact of Covid19 and more importantly how we collectively implement strategies locally and across Te Ika a Māui'
        },
        {
            id: '2',
            item: 'Build a library of strategies for operating and living in a Covid19 environment '
        },
        {
            id: '3',
            item: 'Provide accumulative reporting for quarterly funder reporting'
        }
    ];
    const { control, handleSubmit, formState, setError, setValue, errors, reset, getValues } = useForm({
        defaultValues: { ...item }
    });
    const handleSave = (): void => {
        const data = {
            ...getValues()
        };
        const combinedKey = Math.random().toString(36).substr(2, 9);
        const created_by = sessionStorage.getItem('UserName');
        const saveModel = {
            combinedKey: combinedKey,
            createdBy: created_by,
            state1: data
        };
        dispatch(NTWReportActions.setState1NTWItem(data));
        if (key === '') {
            const savedData = {
                combinedKey: combinedKey,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.saveReportItem(savedData));
        } else {
            const savedData = {
                combinedKey: key,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.updateReportItem(savedData));
        }
    };
    const handleNext = (data): void => {
        setIsActive(true);

        dispatch(NTWReportActions.setState1NTWItem(data));
        dispatch(NTWReportActions.setActiveStep(2));
        setIsActive(false);
    };
    return (
        <IonGrid>
            <form onSubmit={handleSubmit(handleNext)}>
                <IonRow className="container">
                    <IonCol size="12">
                        <h2 className="state3Question">PARTNERSHIP STRUCTURE</h2>
                    </IonCol>
                </IonRow>
                <IonRow className="state3form">
                    <IonCol size="12">
                        <IonItem lines="none">
                            <IonLabel position="fixed" className="formLabel">
                                Name of your partnership:
                                <span className="requiredStar">*</span>
                            </IonLabel>
                            <KFormText
                                name="ProgrammeName"
                                control={control}
                                errors={errors}
                                borders={true}
                                required={true}
                                readonly={submitted}
                            />
                        </IonItem>
                        <IonItem lines="none">
                            <IonLabel position="fixed" className="formLabel">
                                Partners involved in your partnership: <span className="requiredStar">*</span>
                            </IonLabel>
                            <KFormText
                                name="PartnerName"
                                control={control}
                                errors={errors}
                                borders={true}
                                required={true}
                                readonly={submitted}
                            />
                        </IonItem>
                        <div style={{ display: 'inline-flex', fontWeight: '600', color: '#585656' }}>
                            <IonLabel position="fixed">Update Period</IonLabel>

                            <div>
                                <IonLabel position="fixed" style={{ display: 'inline-flex' }} id="dateLabelStart">
                                    Start Date <span className="requiredStar">*</span>
                                </IonLabel>
                                <span style={{ display: 'inline-flex' }}>
                                    <DatePicker
                                        name="startDate"
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        readonly={submitted}
                                    />
                                </span>
                            </div>
                            <div style={{ paddingLeft: '100px' }}>
                                <IonLabel position="fixed" id="dateLabelStart" style={{ display: 'inline-flex' }}>
                                    End Date <span className="requiredStar">*</span>
                                </IonLabel>
                                <span style={{ display: 'inline-flex' }}>
                                    <DatePicker
                                        name="endDate"
                                        selected={endDate}
                                        onChange={date => setEndDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        readonly={submitted}
                                    />
                                </span>
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow className="container">
                    <IonCol size="10"></IonCol>
                    <IonCol size="2" className="buttonSet">
                        <IonButton className="buttonStyle" type="submit">
                            {'Next'}
                        </IonButton>
                        <IonButton className="buttonStyleSave" onClick={handleSave} disabled={submitted}>
                            {'Save'}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </form>
        </IonGrid>
    );
};

export default State1;
