import { call, put, select, takeEvery } from 'redux-saga/effects';

import { NTWReportActions } from '../';
import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { OtherActions, OtherActionTypes } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';

export function* saveReportItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        const key = action.payload.item.combinedKey;
        const created_by = action.payload.item.CreatedBy;
        const item = action.payload.item;
        response = yield call(API.saveItem, key, created_by, item, restService.client);
        yield put(NTWReportActions.setReportItem(response.data));
        if (response.data) {
            yield put(NTWReportActions.setSuccess(true));
            yield put(NTWReportActions.setActiveStep(9));
        } else {
            yield put(NTWReportActions.setSuccess(false));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        yield put(NTWReportActions.setSuccess(false));
        return false;
    }
}

export function* updateReportItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        const key = action.payload.item.combinedKey;

        const created_by = action.payload.item.CreatedBy;
        const item = action.payload.item;
        response = yield call(API.updateItem, key, created_by, item, restService.client);
        yield put(NTWReportActions.setReportItem(response.data));
        if (response.data) {
            yield put(NTWReportActions.setSuccess(true));
            yield put(NTWReportActions.setActiveStep(9));
        } else {
            yield put(NTWReportActions.setSuccess(false));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        yield put(NTWReportActions.setSuccess(false));
        return false;
    }
}

export function* fetchReportItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api

        response = yield call(API.fetchItem, action.payload, restService.client);

        if (response.data) {
            const result = JSON.parse(response.data.result);
            yield put(NTWReportActions.setReportItem(result));
            yield put(NTWReportActions.setState1NTWItem(result.state1));
            yield put(NTWReportActions.setState2NTWItem(result.state2));
            yield put(NTWReportActions.setState3NTWItem(result.state3));
            yield put(NTWReportActions.setState4NTWItem(result.state4));
            yield put(NTWReportActions.setState5NTWItem(result.state5));
            yield put(NTWReportActions.setState6NTWItem(result.state6));
            yield put(NTWReportActions.setState7NTWItem(result.state7));
            yield put(NTWReportActions.setReportKey(action.payload));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        // yield put(NTWReportActions.setActiveStep(5));
        // yield put(NTWReportActions.setSuccess(false));
        // return false;
    }
}

export function* submitReportItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        const key = action.payload.item.combinedKey;
        const created_by = action.payload.item.CreatedBy;
        const item = action.payload.item;
        response = yield call(API.submitItem, key, created_by, item, restService.client);
        yield put(NTWReportActions.setReportItem(response.data));
        if (response.data) {
            yield put(NTWReportActions.setSuccess(true));
            yield put(NTWReportActions.setActiveStep(8));
        } else {
            yield put(NTWReportActions.setSuccess(false));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        yield put(NTWReportActions.setSuccess(false));
        return false;
    }
}

export function* fetchReports(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        const created_by = action.payload;
        response = yield call(API.fetchReports, created_by, restService.client);
        const resultData = response.data;
        yield put(NTWReportActions.setReports(JSON.parse(resultData.result)));
    } catch (error) {
        // If we get an error, set the message
        yield put({ type: error, error: error.message });
    }
}

export const OtherSagas = [
    takeEvery(OtherActionTypes.SaveReportItem, saveReportItem),
    takeEvery(OtherActionTypes.SubmitReportItem, submitReportItem),
    takeEvery(OtherActionTypes.UpdateReportItem, updateReportItem),
    takeEvery(OtherActionTypes.FetchReportItem, fetchReportItem),
    takeEvery(OtherActionTypes.FetchReports, fetchReports)
];
