/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react';
import { FieldErrors } from 'react-hook-form';

import { FormControl } from '../forms/form-control';
import { SelectControl, WTSelectItemControlProps } from '../forms/select-control';

interface KFormSelectProps {
    name: string;
    control: any;
    items: WTSelectItemControlProps[];
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    cancelText?: string;
    confirmText?: string;
    required?: boolean;
    placeholder?: string;
    position?: string;
}

export const KFormSelect: React.FC<KFormSelectProps> = ({
    name,
    control,
    label,
    errors,
    borders,
    background,
    items,
    multiple,
    disabled,
    required,
    placeholder,
    position
}): ReactElement => {
    // Component hooks

    // Component side effects

    // Component private methods

    return (
        <FormControl
            name={name}
            position={position}
            control={control}
            label={label}
            render={({ onChange, value }): ReactElement => (
                <SelectControl
                    onChange={onChange}
                    items={items}
                    value={value}
                    multiple={multiple}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            )}
            errors={errors}
            borders={borders}
            background={background}
        />
    );
};
