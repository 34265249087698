import React, { ReactElement, ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form';

/* eslint-disable react/prop-types */
import { IonCol, IonItem, IonRow } from '@ionic/react';

import { FormControl } from '../forms/form-control';
import { RadioGroupControl } from '../forms/radio-group-control';

interface KFormRadioGroupProps {
    name: string;
    control: any;
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
    items: KRadioItemControlProps[];
    required?: boolean;
    question?: string;
}

interface KRadioItemControlProps {
    value: any;
    label: string | ReactNode;
}

export const KFormRadioGroup: React.FC<KFormRadioGroupProps> = ({
    name,
    control,
    label,
    errors,
    borders,
    background,
    items,
    required,
    question
}): ReactElement => {
    // Component hooks

    // Component side effects

    // Component private methods

    return (
        <IonRow className="radioContainer">
            {question && (
                <IonCol size="8">
                    <IonItem lines="none">{question}</IonItem>
                </IonCol>
            )}
            <IonCol size="4">
                <FormControl
                    name={name}
                    control={control}
                    label={label}
                    render={({ onChange, value }): ReactElement => (
                        <RadioGroupControl onChange={onChange} value={value} items={items} />
                    )}
                    errors={errors}
                    borders={borders}
                    background={background}
                />
            </IonCol>
        </IonRow>
    );
};
