import './home.scss';

import html2canvas from 'html2canvas';
import { alertCircle, informationCircle } from 'ionicons/icons';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers';
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react';
import {
    getState6NTWItem,
    getState5NTWItem,
    getState4NTWItem,
    getState3NTWItem,
    getState2NTWItem,
    getState1NTWItem,
    getReportKey,
    getSubmitted,
    NTWReportActions
} from '@whanau/domains';

import map from '../../assets/map.png';
import { CrudList } from '../components/crud/crud-list';
import { CrudListTwoColumn } from '../components/crud/crud-list_twoColumn';
import { KFormRadioGroup, KFormText, KFormTextarea } from '../components/inputs';
import { Section } from '../components/section';

export const State6 = () => {
    const state1Data = useSelector(getState1NTWItem);
    const state2Data = useSelector(getState2NTWItem);
    const key = useSelector(getReportKey);
    const submitted = useSelector(getSubmitted);
    const state3Data = useSelector(getState3NTWItem);
    const state4Data = useSelector(getState4NTWItem);
    const state5Data = useSelector(getState5NTWItem);
    const item = useSelector(getState6NTWItem);
    const [description, setDescription] = useState('');

    const [list, setList] = useState([]);
    const dispatch = useDispatch();
    const { control, handleSubmit, formState, errors, getValues } = useForm({
        defaultValues: { ...item }
    });
    const [healthAttachmentData, setHealthAttachmentData] = useState({});
    const [isActive, setIsActive] = useState(false);

    const handleBack = (): void => {
        dispatch(NTWReportActions.setActiveStep(5));
    };
    const handleSave = (): void => {
        const data = {
            ...getValues()
        };

        dispatch(NTWReportActions.setState6NTWItem(data));
        const saveModel = {
            state1: state1Data,
            state2: state2Data,
            state3: state3Data,
            state4: state4Data,
            state5: state5Data,
            state6: data
        };
        const combinedKey = Math.random().toString(36).substr(2, 9);
        const created_by = sessionStorage.getItem('UserName');
        if (key === '') {
            const savedData = {
                combinedKey: combinedKey,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.saveReportItem(savedData));
        } else {
            const savedData = {
                combinedKey: key,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.updateReportItem(savedData));
        }
    };

    const onSubmit = (data): void => {
        dispatch(NTWReportActions.setState6NTWItem(data));
        dispatch(NTWReportActions.setActiveStep(7));
    };

    return (
        <LoadingOverlay active={isActive} spinner>
            <div id="divToPrint1">
                <IonGrid>
                    <IonRow className="container">
                        <h1>6. WHĀNAU SUPPORT </h1>
                    </IonRow>
                    <IonRow className="container">
                        <h3 style={{ color: '#b3b3b3' }}>
                            Total number of Whānau supported by your Whānau Ora partnership
                        </h3>
                    </IonRow>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <IonRow class="tableRow" style={{ borderBottom: '1px solid', borderTop: '1px solid' }}>
                            <IonCol
                                size="3"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#3880ff',
                                    borderLeft: '1px solid #000',
                                    borderRight: '1px solid'
                                }}
                            >
                                Individual whānau supported
                                <span className="requiredStar">*</span>
                            </IonCol>
                            <IonCol
                                size="3"
                                style={{ color: '#fff', backgroundColor: '#3880ff', borderRight: '1px solid' }}
                            >
                                Total number of whānau supported
                                <span className="requiredStar">*</span>
                            </IonCol>
                            <IonCol
                                size="3"
                                style={{ color: '#fff', backgroundColor: '#3880ff', borderRight: '1px solid' }}
                            >
                                Total number of Covid19 positive whānau supported
                                <span className="requiredStar">*</span>
                            </IonCol>
                            <IonCol
                                size="3"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#3880ff',
                                    borderRight: '1px solid #000',
                                    borderLeft: '1px solid #000'
                                }}
                            >
                                Total number of close contact whānau supported
                                <span className="requiredStar">*</span>
                            </IonCol>
                        </IonRow>
                        <IonRow class="tableRow" style={{ marginBotttom: '0', borderBottom: '1px solid' }}>
                            <IonCol
                                size="3"
                                style={{ color: '#585656', borderLeft: '1px solid #000', borderRight: '1px solid' }}
                            >
                                <KFormText
                                    name="supported"
                                    control={control}
                                    errors={errors}
                                    borders={false}
                                    required={true}
                                    type="number"
                                    readonly={submitted}
                                />
                            </IonCol>
                            <IonCol size="3" style={{ color: '#585656', borderRight: '1px solid' }}>
                                <KFormText
                                    name="number"
                                    control={control}
                                    errors={errors}
                                    borders={false}
                                    required={true}
                                    type="number"
                                    readonly={submitted}
                                />
                            </IonCol>
                            <IonCol size="3" style={{ color: '#585656', borderRight: '1px solid' }}>
                                <KFormText
                                    name="positivesupport"
                                    control={control}
                                    errors={errors}
                                    borders={false}
                                    required={true}
                                    type="number"
                                    readonly={submitted}
                                />
                            </IonCol>
                            <IonCol
                                size="3"
                                style={{
                                    color: '#585656',
                                    borderRight: '1px solid #000',
                                    borderLeft: '1px solid #000'
                                }}
                            >
                                <KFormText
                                    name="contactsupport"
                                    control={control}
                                    errors={errors}
                                    borders={false}
                                    required={true}
                                    type="number"
                                    readonly={submitted}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow
                            class="tableRow"
                            style={{
                                borderBottom: '1px solid',
                                marginTop: '0'
                            }}
                        >
                            <IonCol
                                size="3"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#3880ff',
                                    borderRight: '1px solid #000',
                                    borderLeft: '1px solid #000'
                                }}
                            >
                                Are you providing support to whānau who are positive and self-isolating at home or
                                linked to a positive case?
                                <span className="requiredStar">*</span>
                            </IonCol>
                            <IonCol
                                size="9"
                                style={{
                                    color: '#585656',
                                    borderRight: '1px solid #000',
                                    borderLeft: '1px solid #000'
                                }}
                            >
                                <KFormText
                                    name="providingsupport"
                                    control={control}
                                    errors={errors}
                                    borders={false}
                                    required={true}
                                    readonly={submitted}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow class="tableRow" style={{ borderBottom: '1px solid' }}>
                            <IonCol
                                size="3"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#3880ff',
                                    borderRight: '1px solid #000',
                                    borderLeft: '1px solid #000'
                                }}
                            >
                                What type of support are you providing?
                                <span className="requiredStar">*</span>
                            </IonCol>
                            <IonCol
                                size="9"
                                style={{
                                    color: '#585656',
                                    borderRight: '1px solid #000',
                                    borderLeft: '1px solid #000'
                                }}
                            >
                                <KFormText
                                    name="typesupport"
                                    control={control}
                                    errors={errors}
                                    borders={false}
                                    required={true}
                                    readonly={submitted}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="container" style={{ marginTop: '50px' }}>
                            <IonCol size="1" className="buttonSet">
                                <IonButton className="buttonStyle" onClick={handleBack}>
                                    {'Back'}
                                </IonButton>
                            </IonCol>
                            <IonCol size="9"></IonCol>
                            <IonCol size="2" className="buttonSet">
                                <IonButton className="buttonStyle" type="submit">
                                    {'Next'}
                                </IonButton>
                                <IonButton className="buttonStyle" onClick={handleSave} disabled={submitted}>
                                    {'Save'}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </form>
                </IonGrid>
            </div>
        </LoadingOverlay>
    );
};
