import './section.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SectionDescription } from './section';

interface SectionItemProps {
    item: string;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
export const SectionDescriptionText: React.FC<SectionItemProps> = ({ item }): ReactElement => {
    // Component state hooks (if any needed)
    const dispatch = useDispatch();

    // Template
    return <p>{item}</p>;
};
