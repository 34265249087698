import './home.scss';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers';
import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonModal, IonRow } from '@ionic/react';
import {
    getState7NTWItem,
    getState6NTWItem,
    getState5NTWItem,
    getState4NTWItem,
    getState3NTWItem,
    getState2NTWItem,
    getState1NTWItem,
    getReportKey,
    getSubmitted,
    NTWReportActions
} from '@whanau/domains';

import map from '../../assets/map.png';
import { KFormRadioGroup, KFormTextarea } from '../components/inputs';
import { Section } from '../components/section';

const schema = yup.object().shape({
    success: yup.string().required('This is a required field')
});

export const State7 = () => {
    const state1Data = useSelector(getState1NTWItem);
    const state2Data = useSelector(getState2NTWItem);
    const state3Data = useSelector(getState3NTWItem);
    const state4Data = useSelector(getState4NTWItem);
    const state5Data = useSelector(getState5NTWItem);
    const state6Data = useSelector(getState6NTWItem);
    const item = useSelector(getState7NTWItem);
    const key = useSelector(getReportKey);
    const submitted = useSelector(getSubmitted);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const { control, handleSubmit, formState, errors, getValues } = useForm({
        defaultValues: { ...item },
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const QuestionList = [
        {
            id: '1',
            question: 'Whānau success stories provide on-the-ground insights about Whānau Ora in action.',
            name: 'success'
        }
    ];
    const [isActive, setIsActive] = useState(false);

    const handleBack = (): void => {
        const data = {
            ...getValues()
        };
        dispatch(NTWReportActions.setState7NTWItem(data));
        dispatch(NTWReportActions.setActiveStep(6));
    };
    const handleSave = (): void => {
        const data = {
            ...getValues()
        };
        dispatch(NTWReportActions.setState7NTWItem(data));
        const saveModel = {
            state1: state1Data,
            state2: state2Data,
            state3: state3Data,
            state4: state4Data,
            state5: state5Data,
            state6: state6Data,
            state7: data
        };

        const combinedKey = Math.random().toString(36).substr(2, 9);
        const created_by = sessionStorage.getItem('UserName');
        if (key === '') {
            const savedData = {
                combinedKey: combinedKey,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.saveReportItem(savedData));
        } else {
            const savedData = {
                combinedKey: key,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.updateReportItem(savedData));
        }
    };
    const handleNext = (data): void => {
        dispatch(NTWReportActions.setState7NTWItem(data));
        setModalOpen(true);
        // dispatch(NTWReportActions.setActiveStep(8));
    };
    const exit = (): void => {
        window.close();
    };
    const handleClose = (): void => {
        const DataToBeSaved = item;
        const combinedKey = Math.random().toString(36).substr(2, 9);
        const created_by = sessionStorage.getItem('UserName');
        const saveModel = {};

        if (key === '') {
            const savedData = {
                combinedKey: combinedKey,
                CreatedBy: created_by,
                state1: state1Data,
                state2: state2Data,
                state3: state3Data,
                state4: state4Data,
                state5: state5Data,
                state6: state6Data,
                state7: DataToBeSaved
            };
            dispatch(NTWReportActions.submitReportItem(savedData));
        } else {
            const savedData = {
                combinedKey: key,
                CreatedBy: created_by,
                state1: state1Data,
                state2: state2Data,
                state3: state3Data,
                state4: state4Data,
                state5: state5Data,
                state6: state6Data,
                state7: DataToBeSaved
            };
            dispatch(NTWReportActions.submitReportItem(savedData));
        }
        setModalOpen(false);
    };

    return (
        <LoadingOverlay active={isActive} spinner>
            <div id="divToPrint1">
                <IonGrid>
                    <IonRow className="container">
                        <h1>7. Whānau Success Story </h1>
                    </IonRow>
                    <form onSubmit={handleSubmit(handleNext)}>
                        <IonRow class="row">
                            <IonCol size="12">
                                <div className="container">
                                    {QuestionList && QuestionList.length > 0
                                        ? QuestionList.map(Question => (
                                              <div key={Question.id}>
                                                  <div>
                                                      <p style={{ marginBottom: '0' }}>
                                                          {Question.question}
                                                          <span className="requiredStar">*</span>
                                                      </p>
                                                  </div>

                                                  <KFormTextarea
                                                      name={Question.name}
                                                      control={control}
                                                      errors={errors}
                                                      borders={true}
                                                      required={false}
                                                      rows={10}
                                                      readonly={submitted}
                                                  />
                                              </div>
                                          ))
                                        : ''}
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className="container">
                            <IonCol size="1">
                                <IonButton className="buttonStyleSave" onClick={handleBack}>
                                    {'Back'}
                                </IonButton>
                            </IonCol>
                            <IonCol size="9"></IonCol>
                            <IonCol size="2" className="buttonSet">
                                {!submitted && (
                                    <IonButton
                                        className="buttonStyle"
                                        type="submit"
                                        disabled={formState.isValid === false}
                                    >
                                        {'Submit'}
                                    </IonButton>
                                )}
                                {submitted && (
                                    <IonButton className="buttonStyle" onClick={exit}>
                                        {'Exit'}
                                    </IonButton>
                                )}
                                <IonButton className="buttonStyle" onClick={handleSave} disabled={submitted}>
                                    {'Save'}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </form>
                </IonGrid>
            </div>
            <IonModal isOpen={modalOpen} backdropDismiss={false} cssClass="instance-selector-wrapper">
                <IonContent className="instance-selector-modal-wrapper">
                    <IonGrid>
                        <IonRow>
                            <h3 className="headingPadding">
                                Once you submit your record you cannot edit it. Are you sure you want to Submit?
                            </h3>
                        </IonRow>
                        <IonRow>
                            <IonCol size="4"></IonCol>
                            <IonCol size="4">
                                <IonButton
                                    onClick={() => handleClose()}
                                    size="default"
                                    expand="block"
                                    color="secondary"
                                    class="instanceButton buttonColor"
                                    style={{ background: 'rgb(7, 7, 49)' }}
                                >
                                    Submit
                                </IonButton>
                            </IonCol>
                            <IonCol size="4"></IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonModal>
        </LoadingOverlay>
    );
};
