import './form-control.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';

import { IonItem, IonLabel, IonNote } from '@ionic/react';

interface childHubControlProps {
    name: string;
    control: any;
    render: any;
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
    position?: string;
}

export const FormControl: React.FC<childHubControlProps> = ({
    name,
    control,
    render,
    label,
    errors,
    borders,
    background,
    position
}): ReactElement => {
    // Component hooks
    const [error, setError] = useState(null);

    // Component side effects
    useEffect(() => {
        setError((errors && errors[name]) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors[name]]);

    // Component private methods

    return (
        <IonItem
            className={`form-control ${borders && 'has-borders'} ${background && 'has-background'} ${
                error && 'has-error'
            }`}
            lines="none"
        >
            {label && (
                <IonLabel color="primary" position="fixed">
                    {label}
                </IonLabel>
            )}
            <Controller name={name} control={control} render={render} />
            {error && <IonNote color="danger">{error.message}</IonNote>}
        </IonItem>
    );
};
