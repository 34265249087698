import { useDispatch, useSelector } from 'react-redux';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { authActions } from '../../_auth';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { nhiActions } from '../nhi/actions';
import { NavigatorActions, NavigatorActionTypes } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';
import { getNavigatorFilters, getNavigatorInstance } from './selectors';

/**
 * Navigator Sagas
 */
export function* fetchNavigatorList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    const url_string = window.location.href;
    const url = new URL(url_string);
    const instanceData = JSON.parse(url.searchParams.get('data'));

    try {
        // set filters from selector
        const parameters: Partial<CONTRACTS.navigatorSearchParameters> = yield select(getNavigatorFilters);
        // Call API (asynchronous, so yield)
        const navigatorInstance = instanceData.resource;

        const response = yield call(API.fetchNavigatorList, navigatorInstance, restService.client);
        // Update store
        yield put(NavigatorActions.setNavigatorList(Array.isArray(response) ? response : []));

        yield put(NavigatorActions.setTotalInNavigatorFilters(response.length));
        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
        yield put(NavigatorActions.setSuccess(false));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}
export function* searchNavigator(action): any {
    //const Navigator: CONTRACTS.navigatorSearchParameters = action.payload;
    const Navigator = action.payload;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const instanceData = JSON.parse(url.searchParams.get('data'));
    if (Navigator) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());
        // Try to delete the delevery
        try {
            // Call Auth API (asynchronous, so yield)
            const navigatorInstance = instanceData.resource;
            const response = yield call(API.searchNavigator, Navigator, navigatorInstance, restService.client);

            if (response.length !== 0) {
                yield put(NavigatorActions.setNavigatorList(Array.isArray(response) ? response : []));
                yield put(NavigatorActions.setData(false));
            } else {
                yield put(NavigatorActions.setNavigatorList([]));
                yield put(NavigatorActions.setData(true));
            }

            // yield put(NavigatorActions.setSuccess(true));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            // yield put(NavigatorActions.setSuccess(false));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* fetchNavigatorbyNHI(action): any {
    const NavigatorId = action.payload;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const instanceData = JSON.parse(url.searchParams.get('data'));
    if (NavigatorId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the delevery
        try {
            // Call Auth API (asynchronous, so yield)
            const navigatorInstance = instanceData.resource;
            const response = yield call(API.fetchNavigatorbyNHI, NavigatorId, navigatorInstance, restService.client);

            yield put(NavigatorActions.setNavigator(response));
            // yield put(NavigatorActions.setSuccess(true));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            // yield put(NavigatorActions.setSuccess(false));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeNavigator(action): any {
    let response;

    const Navigator: CONTRACTS.navigator = action.payload;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const instanceData = JSON.parse(url.searchParams.get('data'));
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    // Try to update or create the delevery
    try {
        //Call update api
        const navigatorInstance = instanceData.resource;
        response = yield call(API.storeNavigator, Navigator, navigatorInstance, restService.client);
        const sendResponse = {
            type: 'removenotice',
            guid: response.contactid
        };
        yield put(NavigatorActions.setSuccess(true));
        yield put(NavigatorActions.setError(false));
        yield put(NavigatorActions.setNavigatorList([]));
        yield put(nhiActions.setNhiList([]));
        yield put(NavigatorActions.clearItemId());
        yield put(nhiActions.clearNHIItemId());
        yield put(NavigatorActions.setNavigatorResponse(sendResponse));
        // //update the list after update or create
        // yield put(NavigatorActions.updateNavigatorList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage, response.id));
    } catch (error) {
        // If we get an error, set the message
        yield put(NavigatorActions.setError(true));
        window.alert(error.detail);
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        yield put(NavigatorActions.setSuccess(false));

        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* setNavigatorFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.navigatorFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.navigatorFilters> = yield select(getNavigatorFilters);

    //Update filters
    yield put(NavigatorActions.storeNavigatorFilters({ ...existingFilters, ...filters }));

    yield* fetchNavigatorList(action);
}
export function* getNavigatorInstanceList(action): any {
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to delete the delevery
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(API.getNavigatorInstanceList, restService.client);

        yield put(NavigatorActions.setNavigatorInstanceList(response));
        yield put(NavigatorActions.setShowModalForInstance(true));
        // yield put(NavigatorActions.setSuccess(true));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        // yield put(NavigatorActions.setSuccess(false));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export const nhiLookupNavigatorSagas = [
    takeEvery(NavigatorActionTypes.FetchNavigatorList, fetchNavigatorList),
    takeEvery(NavigatorActionTypes.SetNavigatorFilters, setNavigatorFilters),
    takeEvery(NavigatorActionTypes.SearchNavigator, searchNavigator),
    takeEvery(NavigatorActionTypes.FetchNavigatorbyNHI, fetchNavigatorbyNHI),
    takeEvery(NavigatorActionTypes.StoreNavigator, storeNavigator),
    takeEvery(NavigatorActionTypes.GetNavigatorInstanceList, getNavigatorInstanceList)
];
