import './home.scss';

import html2canvas from 'html2canvas';
import { alertCircle, informationCircle } from 'ionicons/icons';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers';
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToast
} from '@ionic/react';
import {
    getState5NTWItem,
    getState4NTWItem,
    getState3NTWItem,
    getState2NTWItem,
    getState1NTWItem,
    getReportKey,
    getSubmitted,
    NTWReportActions
} from '@whanau/domains';

import map from '../../assets/map.png';
import { CrudListNoHeader } from '../components/crud/crud-list_noHeader';
import { CrudListTwoColumn } from '../components/crud/crud-list_twoColumn';
import { KFormRadioGroup, KFormText, KFormTextarea } from '../components/inputs';
import { Section } from '../components/section';

const schema = yup.object().shape({
    Provider5: yup.string().required('This is a required field'),
    tests: yup.number().required('This is a required field'),
    positive: yup.number().required('This is a required field')
});
export const State5 = () => {
    const state1Data = useSelector(getState1NTWItem);
    const state2Data = useSelector(getState2NTWItem);
    const key = useSelector(getReportKey);
    const submitted = useSelector(getSubmitted);
    const state3Data = useSelector(getState3NTWItem);
    const state4Data = useSelector(getState4NTWItem);
    const item = useSelector(getState5NTWItem);
    const [list, setList] = useState([]);
    const dispatch = useDispatch();
    const { control, handleSubmit, formState, errors, getValues } = useForm({
        defaultValues: { ...item },
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    // Component side effects
    // Hide element if it's been deleted successfully
    const [saveToastVisible, setSaveToastVisible] = useState(false);
    const [saveToastColor, setSaveToastColor] = useState('danger');
    const [saveToastMessage, setSaveToastMessage] = useState('');
    useEffect(() => {
        if (item !== undefined) {
            setList(item);
        }
    }, [item]);
    const handleBack = (): void => {
        dispatch(NTWReportActions.setActiveStep(4));
    };
    const handleRemove = (id): void => {
        const storedData = item;
        const oldList = list;
        const newArray = oldList.slice();
        const index = storedData.findIndex(p => p.id == id);
        newArray.splice(index, 1);
        newArray.filter(val => val);
        dispatch(NTWReportActions.setState5NTWItem(newArray));
    };
    const handleAdd = (): void => {
        const data = {
            ...getValues()
        };
        if (
            data.Provider5.replace(/\s/g, '').length > 0 &&
            data.tests.replace(/\s/g, '').length > 0 &&
            data.positive.replace(/\s/g, '').length > 0
        ) {
            data.id = Math.floor(Math.random() * 10000);
            if (list.length == 0 || list.length === undefined) {
                const freshArr = [];
                freshArr.push(data);
                dispatch(NTWReportActions.setState5NTWItem(freshArr));
            } else {
                const oldList = list;
                const newArray = oldList.slice();
                newArray.push(data);
                dispatch(NTWReportActions.setState5NTWItem(newArray));
            }
        } else {
            setSaveToastVisible(true);
            setSaveToastMessage('Please enter some information before adding');
        }
    };
    const handleSave = (): void => {
        const data = item;
        const saveModel = {
            state1: state1Data,
            state2: state2Data,
            state3: state3Data,
            state4: state4Data,
            state5: data
        };
        const combinedKey = Math.random().toString(36).substr(2, 9);
        const created_by = sessionStorage.getItem('UserName');
        if (key === '') {
            const savedData = {
                combinedKey: combinedKey,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.saveReportItem(savedData));
        } else {
            const savedData = {
                combinedKey: key,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.updateReportItem(savedData));
        }
    };
    const handleNext = (data): void => {
        dispatch(NTWReportActions.setActiveStep(6));
    };

    return (
        <div id="divToPrint1">
            <IonGrid>
                <IonRow className="container">
                    <h1>5. COVID19 TESTING </h1>
                </IonRow>
                <IonRow style={{ marginBottom: '80px' }}>
                    <IonCol>
                        <IonItem className="patient-list-label" lines="none">
                            <IonLabel color="primary">
                                Covid19 Testing Sites supported or led by your individual Whānau Ora partners.
                            </IonLabel>
                            <IonIcon slot="start" icon={informationCircle} color="dark"></IonIcon>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <form>
                    <IonRow className="container">
                        <IonCol size="6" style={{ color: '#000000' }}>
                            <IonLabel>Name of the Provider</IonLabel>
                            <KFormText
                                name="Provider5"
                                control={control}
                                errors={errors}
                                borders={true}
                                required={true}
                                readonly={submitted}
                            />
                        </IonCol>
                        <IonCol size="6" style={{ color: '#000000' }}>
                            <IonLabel>Total number of tests </IonLabel>
                            <KFormText
                                name="tests"
                                control={control}
                                errors={errors}
                                borders={true}
                                required={true}
                                type="number"
                                readonly={submitted}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="container">
                        <IonCol size="6" style={{ color: '#000000' }}>
                            <IonLabel>Total number of positive tests</IonLabel>
                            <KFormText
                                name="positive"
                                control={control}
                                errors={errors}
                                borders={true}
                                required={true}
                                type="number"
                                readonly={submitted}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="container" style={{ marginTop: '20px' }}>
                        <IonCol size="10"></IonCol>
                        <IonCol size="2" className="buttonSet">
                            <IonButton
                                className="buttonStyle"
                                onClick={handleAdd}
                                disabled={formState.isValid === false || submitted}
                            >
                                {'Add'}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
                <IonToast
                    isOpen={saveToastVisible}
                    onDidDismiss={(): void => {
                        setSaveToastVisible(false);
                        setSaveToastMessage('');
                    }}
                    message={saveToastMessage}
                    position="bottom"
                    color={saveToastColor}
                    duration={1000}
                />
                <IonRow class="row">
                    <IonCol size="12">{list && <CrudListTwoColumn Items={list} onRemove={handleRemove} />}</IonCol>
                </IonRow>
                <IonRow className="container" style={{ marginTop: '20px' }}>
                    <IonCol size="1" className="buttonSet">
                        <IonButton className="buttonStyle" onClick={handleBack}>
                            {'Back'}
                        </IonButton>
                    </IonCol>
                    <IonCol size="9"></IonCol>
                    <IonCol size="2" className="buttonSet">
                        {list.length > 0 && (
                            <IonButton className="buttonStyle" onClick={handleNext}>
                                {'Next'}
                            </IonButton>
                        )}
                        {list.length > 0 && (
                            <IonButton className="buttonStyle" onClick={handleSave} disabled={submitted}>
                                {'Save'}
                            </IonButton>
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};
