import './consentScreen.scss';

import React from 'react';
import { useDispatch } from 'react-redux';

import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import { NTWReportActions } from '@whanau/domains';

import { Section } from '../components/section';

export const SavedPage = () => {
    const dispatch = useDispatch();
    const page1Title = 'Saved!';
    const exit = (): void => {
        window.close();
    };
    return (
        <IonGrid>
            <IonRow class="sectionHeading">
                <IonCol size="12">
                    <Section sectionMainTitle={page1Title} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="consentBox">
                    <p>Your information has been saved.</p>
                    <p>Please use Whānau Tahi login details to resume.</p>
                    <p>We look forward to receiving your request for additional need.</p>
                </IonCol>
            </IonRow>
            <IonRow className="container">
                <IonCol size="10"></IonCol>
                <IonCol size="2" className="buttonSet">
                    <IonButton className="buttonStyle" onClick={exit}>
                        {'Exit'}
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default SavedPage;
