import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State6ActionTypes {
    FetchItem = 'State6/fetchItem',
    SetState6NTWItem = 'State6/setState6NTWItem'
}
/**
 * Actions
 */
export const State6Actions = {
    setState6NTWItem: (item): ActionWithPayload<State6ActionTypes.SetState6NTWItem, { item: CONTRACTS.Item }> =>
        createAction(State6ActionTypes.SetState6NTWItem, { item })
};
