import React, { ReactElement, ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form';

/* eslint-disable react/prop-types */
import { IonCheckbox } from '@ionic/react';

import { CheckboxControl } from '../forms/checkbox-control';
//import { KFormKaimahiCheckboxControl, KFormKaimahiControl } from '../forms/checkbox-control';
import { RadioGroupControl } from '../forms/radio-group-control';

interface KFormCheckboxProps {
    name: string;
    control: any;
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
    items: KRadioItemControlProps;
}

interface KRadioItemControlProps {
    id: any;
    label: string | ReactNode;
}

export const KFormCheckbox: React.FC<KFormCheckboxProps> = ({
    name,
    control,
    label,
    errors,
    borders,
    background,
    items
}): ReactElement => {
    // Component hooks

    // Component side effects

    // Component private methods

    return (
        <CheckboxControl
            name={name}
            control={control}
            label={label}
            render={({ onChange, value }): ReactElement => (
                <IonCheckbox value={value} onIonChange={onChange} slot="start" />
            )}
            errors={errors}
            borders={borders}
            background={background}
        />
    );
};
