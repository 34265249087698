import * as CONTRACTS from './contracts';

export const getTermsNTW = (state: any): boolean => {
    return state.NTWReport.Other.terms || false;
};
export const getActiveStepNTW = (state: any): number => {
    return state.NTWReport.Other.activeStep || 0;
    //return 7;
};
export const getReportList = (state: any): [] => {
    return state.NTWReport.Other.list || {};
};
export const getReportKey = (state: any): string => {
    return state.NTWReport.Other.key || '';
};
export const getSubmitted = (state: any): boolean => {
    return state.NTWReport.Other.isSubmitted || false;
};
// export const getSuccess = (state: any): boolean => {
//     return state.ChildHub.Other.success || false;
// };
// export const getResponse = (state: any): string => {
//     return state.ChildHub.Other.response || '';
// };
