import './crud-list-item.scss';

import { trash } from 'ionicons/icons';
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    AlertButton,
    CreateAnimation,
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonRow
} from '@ionic/react';
import {
    NTWReportActions,
    setItemId,
    SystemMessageSeverity,
    SystemMessageType,
    getSubmitted,
    SystemSelectors
} from '@whanau/domains';

import { CRUDListItemNoHeader } from './crud-list_noHeader';

/**
 * Crud List Item Props
 */
interface CrudListItemProps {
    item: CRUDListItemNoHeader;
    onRemove?: (id) => void;
    onShowAlert: (header: string, buttons: AlertButton[]) => void;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
const CrudListItemNoHeader: React.FC<CrudListItemProps> = ({ onShowAlert, onRemove, item }) => {
    // Environment
    // Component state hooks
    const dispatch = useDispatch();
    const submitted = useSelector(getSubmitted);
    const [hide, setHide] = useState(false);
    const lastMessage = useSelector(SystemSelectors.lastMessage);
    const handleRemove = async (): Promise<void> => {
        onRemove(item.Number);
    };
    // Template
    return (
        <CreateAnimation
            duration={250}
            keyframes={[
                { offset: 0, transform: 'translateX(0) scale(1)', opacity: 1 },
                { offset: 0.8, transform: 'translateX(450px) scale(1)', opacity: 0 },
                { offset: 1, transform: 'translateX(450px) scale(0)', opacity: 0 }
            ]}
            afterStyles={{
                display: 'none'
            }}
            play={hide}
        >
            <IonItem>
                <IonGrid className="list">
                    <IonRow>
                        <IonCol size="3" style={{ color: '#000000' }}>
                            {item.Provider4}
                        </IonCol>
                        <IonCol size="3" style={{ color: '#000000' }}>
                            {item.Location}
                        </IonCol>
                        <IonCol size="3" style={{ color: '#000000' }}>
                            {item.Address}
                        </IonCol>

                        <IonCol size="3" style={{ color: '#000000' }}>
                            {item.Days}
                            <span style={{ float: 'right', color: 'red' }}>
                                <IonButton
                                    fill="clear"
                                    onClick={(): Promise<void> => handleRemove()}
                                    disabled={submitted}
                                >
                                    <IonIcon size="small" icon={trash} slot="end" />
                                </IonButton>
                            </span>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonItem>
        </CreateAnimation>
    );
};

// Exporting memoized component
export default React.memo(CrudListItemNoHeader);
