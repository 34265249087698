import './home.scss';

import { format } from 'date-fns';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonNav,
    IonNote,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { getActiveStepNTW, getTermsNTW, NTWReportActions, SystemSelectors } from '@whanau/domains';

import MultiStepProgressBar from '../components/progress_bar';
import { Section } from '../components/section';
// import logo1 from '../logo.svg';
import logo from '../pages/logo-inverted.png';
import ConsentScreen from './consentScreen';
import MainPage from './mainpage';
import SavedPage from './saved';
import SomethingWentWrong from './somethingWentWrong';
import ContactSupportPage from './Sorry_support';
import { State1 } from './state1';
import { State2 } from './state2';
import { State3 } from './state3';
import { State4 } from './state4';
import { State5 } from './state5';
import { State6 } from './state6';
import { State7 } from './state7';
import Submitted from './Submited';

export const Home = () => {
    const dispatch = useDispatch();
    const activeStep = useSelector(getActiveStepNTW);
    const [currentStep, setCurrentStep] = useState(null);
    const checked = useSelector(getTermsNTW);

    const page1Title = 'WELCOME TO THE CHILD HUB COLLABORATIVE!';
    const descriptionList = [
        {
            id: '1',
            item:
                'The Child Hub Collaborative is a joint initiative between MidCentral Health and Ministry of Education to trial a common pathway to improve alignment between health and education service referral and provision, for whānau with children 0-8 years of age with additional needs - disabilities and developmental delays.'
        },
        {
            id: '2',
            item:
                "The aim is to provide this common pathway for whānau to access and experience 'joined up' service delivery, across health and education services through multiple agencies. The Child Hub Collaborative team will work in partnership with your whānau and child to coordinate across sector services to support your child's additional needs identified. "
        },
        {
            id: '3',
            item:
                'The Child Hub Collaborative will provide a means to trial this pathway, with the opportunity to adapt and improve this, prior to this combined pathway being offered on a wider scale. For this reason, we have initially focused on improving the experience for whānau with children requiring a combination of health and education services, as a basis to contain the pilot, and within a subset of the Manawatu area, prior to expanding wider post pilot. '
        }
    ];
    useEffect(() => {
        setCurrentStep(activeStep);
    }, [activeStep]);
    const renderStepContent = (step): ReactElement => {
        switch (step) {
            case 0:
                return <MainPage />;
            case 1:
                return <State1 />;
            case 2:
                return <State2 />;
            case 3:
                return <State3 />;
            case 4:
                return <State4 />;
            case 5:
                return <State5 />;
            case 6:
                return <State6 />;
            case 7:
                return <State7 />;
            case 8:
                return <Submitted />;
            case 9:
                return <SavedPage />;
            default:
                return <State1 />;
        }
    };
    const handleNext = (): void => {
        if (currentStep === null) {
            dispatch(NTWReportActions.setActiveStep(0));
            setCurrentStep(0);
        } else {
            dispatch(NTWReportActions.setActiveStep(1));
            setCurrentStep(1);
        }
    };
    return (
        <IonPage>
            <IonHeader class="new-background-color">
                <IonToolbar class="new-background-color">
                    <IonTitle>
                        <IonRow>
                            <IonCol size="4">
                                <img alt="logo" height="60" src={logo} style={{ display: 'inline', zIndex: 4 }} />
                            </IonCol>
                            <IonCol size="8" class="title">
                                Covid19 Dashboard
                            </IonCol>
                        </IonRow>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {currentStep > 0 && currentStep < 8 && <MultiStepProgressBar currentStep={currentStep} />}
                {renderStepContent(currentStep)}
            </IonContent>
        </IonPage>
    );
};

export default Home;
