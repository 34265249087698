import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum OtherActionTypes {
    FetchItem = 'Other/fetchItem',
    SetReportItem = 'Other/setReportItem',
    SetReports = 'Other/setReports',
    SetReportKey = 'Other/setReportKey',
    SetSubmitted = 'Other/setSubmitted',
    StoreItem = 'Other/storeItem',
    SetTerms = 'Other/setTerms',
    SetActiveStep = 'Other/setActiveStep',
    SetSuccess = 'Other/setSuccess',
    SetResponse = 'Other/setResponse',
    SaveReportItem = 'Other/saveReportItem',
    UpdateReportItem = 'Other/updateReportItem',
    FetchReportItem = 'Other/fetchReportItem',
    FetchReports = 'Other/fetchReports',
    SubmitReportItem = 'Other/submitReportItem'
}
/**
 * Actions
 */
export const OtherActions = {
    setReportKey: (key): ActionWithPayload<OtherActionTypes.SetReportKey, { key: string }> =>
        createAction(OtherActionTypes.SetReportKey, { key }),
    setSubmitted: (isSubmitted): ActionWithPayload<OtherActionTypes.SetSubmitted, { isSubmitted: boolean }> =>
        createAction(OtherActionTypes.SetSubmitted, { isSubmitted }),
    setTerms: (terms: boolean): ActionWithPayload<OtherActionTypes.SetTerms, { terms: boolean }> =>
        createAction(OtherActionTypes.SetTerms, { terms }),
    setActiveStep: (activeStep: number): ActionWithPayload<OtherActionTypes.SetActiveStep, { activeStep: number }> =>
        createAction(OtherActionTypes.SetActiveStep, { activeStep }),
    setSuccess: (success: boolean): ActionWithPayload<OtherActionTypes.SetSuccess, { success: boolean }> =>
        createAction(OtherActionTypes.SetSuccess, { success }),
    setResponse: (response: string): ActionWithPayload<OtherActionTypes.SetResponse, { response: string }> =>
        createAction(OtherActionTypes.SetResponse, { response }),
    setReportItem: (item): ActionWithPayload<OtherActionTypes.SetReportItem, { item: CONTRACTS.item }> =>
        createAction(OtherActionTypes.SetReportItem, { item }),
    setReports: (list): ActionWithPayload<OtherActionTypes.SetReports, { list }> =>
        createAction(OtherActionTypes.SetReports, { list }),
    saveReportItem: (item): ActionWithPayload<OtherActionTypes.SaveReportItem, { item: CONTRACTS.item }> =>
        createAction(OtherActionTypes.SaveReportItem, { item }),
    submitReportItem: (item): ActionWithPayload<OtherActionTypes.SubmitReportItem, { item: CONTRACTS.item }> =>
        createAction(OtherActionTypes.SubmitReportItem, { item }),
    updateReportItem: (item): ActionWithPayload<OtherActionTypes.UpdateReportItem, { item: CONTRACTS.item }> =>
        createAction(OtherActionTypes.UpdateReportItem, { item }),
    fetchReportItem: (key): ActionWithPayload<OtherActionTypes.FetchReportItem, { key: string }> =>
        createAction(OtherActionTypes.FetchReportItem, key),
    fetchReports: (created_by): ActionWithPayload<OtherActionTypes.FetchReports, { created_by: string }> =>
        createAction(OtherActionTypes.FetchReports, created_by)
};
