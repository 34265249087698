import './home.scss';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers';
import { IonButton, IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import { getState2NTWItem, getState1NTWItem, getSubmitted, getReportKey, NTWReportActions } from '@whanau/domains';

import map from '../../assets/map.png';
import { KFormRadioGroup, KFormText, KFormTextarea } from '../components/inputs';
import { Section } from '../components/section';

export const State2 = () => {
    const state1Data = useSelector(getState1NTWItem);
    const item = useSelector(getState2NTWItem);
    const key = useSelector(getReportKey);
    const submitted = useSelector(getSubmitted);
    const dispatch = useDispatch();
    const { control, handleSubmit, formState, errors, register, getValues } = useForm({
        defaultValues: { ...item },
        mode: 'onChange'
    });
    const [isActive, setIsActive] = useState(false);
    const handleSave = (): void => {
        const data = {
            ...getValues()
        };
        //add api setting
        const saveModel = {
            state1: state1Data,
            state2: data
        };
        dispatch(NTWReportActions.setState2NTWItem(data));
        const combinedKey = Math.random().toString(36).substr(2, 9);
        const created_by = sessionStorage.getItem('UserName');
        if (key === '') {
            const savedData = {
                combinedKey: combinedKey,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.saveReportItem(savedData));
        } else {
            const savedData = {
                combinedKey: key,
                CreatedBy: created_by,
                jsonstring: JSON.stringify(saveModel)
            };
            dispatch(NTWReportActions.updateReportItem(savedData));
        }
    };
    const handleBack = (): void => {
        const data = {
            ...getValues()
        };
        dispatch(NTWReportActions.setState2NTWItem(data));
        dispatch(NTWReportActions.setActiveStep(1));
    };
    const onSubmit = (data): void => {
        dispatch(NTWReportActions.setState2NTWItem(data));
        dispatch(NTWReportActions.setActiveStep(3));
    };

    return (
        <div id="divToPrint1">
            <IonGrid>
                <IonRow className="container">
                    <h1>2. DELTA RAPID RESPONSE DATA </h1>
                </IonRow>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonRow class="row">
                        <IonCol size="12">
                            <div className="container">
                                <IonGrid style={{ border: '1px solid' }}>
                                    <IonRow
                                        style={{
                                            borderBottom: '1px solid',
                                            backgroundColor: '#fdfd96',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Activity
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            # Number
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            $ Value
                                        </IonCol>
                                        <IonCol size="5">
                                            Whānau Ora investment line (Delta, Innovation, and Kaiārahi etc.)
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Kai Packs
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="KaiPacksNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="KaiPacksValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="KaiPacksInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Hygiene Packs
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="HygienePacksNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="HygienePacksValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="HygienePacksInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Supermarket Vouchers
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="SupermarketVouchersNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="SupermarketVouchersValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="SupermarketVouchersInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Petrol Vouchers
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="PetrolVouchersNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="PetrolVouchersValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="PetrolVouchersInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Education
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="EducationNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="EducationValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="EducationInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Kaimahi
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="KaimahiNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="KaimahiValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="KaimahiInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Technology
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="TechnologyNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="TechnologyValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="TechnologyInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Training
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="TrainingNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="TrainingValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="TrainingInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ textAlign: 'center' }}>
                                        <IonCol size="3" style={{ borderRight: '1px solid' }}>
                                            Resource
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="ResourceNumber"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="2" style={{ borderRight: '1px solid' }}>
                                            <KFormText
                                                name="ResourceValue"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                type="number"
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                        <IonCol size="5">
                                            <KFormText
                                                name="ResourceInvestment"
                                                control={control}
                                                errors={errors}
                                                borders={false}
                                                required={true}
                                                readonly={submitted}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="container">
                        <IonCol size="1" className="buttonSet">
                            <IonButton className="buttonStyle" onClick={handleBack}>
                                {'Back'}
                            </IonButton>
                        </IonCol>
                        <IonCol size="9"></IonCol>
                        <IonCol size="2" className="buttonSet">
                            <IonButton className="buttonStyle" type="submit">
                                {'Next'}
                            </IonButton>
                            <IonButton className="buttonStyle" onClick={handleSave} disabled={submitted}>
                                {'Save'}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            </IonGrid>
        </div>
    );
};
