import './crud-list.scss';
import $ from 'jquery';
import { info } from 'console';
import { alertCircle, informationCircle } from 'ionicons/icons';
/* eslint-disable react/prop-types */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    AlertButton,
    IonAlert,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonRow,
    IonText
} from '@ionic/react';
import { getReportKey } from '@whanau/domains';

import CrudListItemReports from './crud-list-item-reports';
import { CrudListPagination } from './crud-list-pagination';

export interface CRUDListReports {
    id: string;
    ProgrammeNames: string;
    combinedKey?: string;
    PartnerName?: string;
    startDate?: Date;
    endDate?: Date;
    Submitted?: boolean;
}

/**
 * Crud List Props
 */
interface CRUDListProps {
    Items: CRUDListReports[];
    noItemsLabel?: string;
    onRemove?: (id) => void;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
export const CrudListReports: React.FC<CRUDListProps> = ({ Items, onRemove, noItemsLabel }): ReactElement => {
    // Component state hooks (if any needed)
    const dispatch = useDispatch();
    const key = useSelector(getReportKey);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState('');
    const [alertButtons, setAlertButtons] = useState<(AlertButton | string)[]>([]);
    const [label, setLabel] = useState('');
    // Component private methods
    // Handle alert status and contents
    const handleShowAlert = useCallback((header: string, buttons: AlertButton[]) => {
        setAlertHeader(header);
        setAlertButtons(buttons);
        setShowAlert(true);
    }, []);
    // Remove item
    const handleRemove = async (id: string): Promise<void> => {
        await onRemove(id);
    };
    useEffect(() => {
        $('.enableRow').removeClass('highlight');
        if (key !== '') {
            const id = '#' + key;
            $(id).addClass('highlight');
        }
    }, [key]);
    // Template
    return (
        <>
            <IonGrid className="mainList">
                <IonRow>
                    <IonCol>
                        <IonItem className="patient-list-grid">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="2" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Programme Name</IonLabel>
                                    </IonCol>
                                    <IonCol size="2" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Partner Name</IonLabel>
                                    </IonCol>
                                    <IonCol size="2" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Start Date</IonLabel>
                                    </IonCol>
                                    <IonCol size="2" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>End Date</IonLabel>
                                    </IonCol>
                                    <IonCol size="2" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Combined Key</IonLabel>
                                    </IonCol>
                                    <IonCol size="2" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Status</IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                        <IonList className="patient-list">
                            {Items && Items.length > 0
                                ? Items.map((Item, index) => (
                                      <CrudListItemReports onShowAlert={handleShowAlert} key={index} item={Item} />
                                  ))
                                : 'No Reports created. Create one by clicking Next.'}
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <IonAlert
                isOpen={showAlert}
                header={alertHeader}
                buttons={alertButtons}
                onDidDismiss={(): void => setShowAlert(false)}
            ></IonAlert>
        </>
    );
};
