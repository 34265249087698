// import './form-control.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react';
import { FieldErrors } from 'react-hook-form';

import { IonTextarea } from '@ionic/react';

import { FormControl } from '../forms/form-control';

interface KFormTextareaProps {
    name: string;
    control: any;
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
    readonly?: boolean;
    placeholder?: string;
    required?: boolean;
    rows?: number;
}

export const KFormTextarea: React.FC<KFormTextareaProps> = ({
    name,
    control,
    label,
    errors,
    borders,
    background,
    readonly,
    placeholder,
    required,
    rows
}): ReactElement => {
    // Component hooks

    // Component side effects

    // Component private methods

    return (
        <FormControl
            name={name}
            control={control}
            label={label}
            render={({ onChange, value }): ReactElement => (
                <IonTextarea
                    onIonInput={onChange}
                    value={value}
                    readonly={readonly}
                    debounce={4}
                    placeholder={placeholder}
                    required={required}
                    rows={rows}
                    autoGrow={true}
                />
            )}
            errors={errors}
            borders={borders}
            background={background}
        />
    );
};
