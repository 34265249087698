import './crud-list-item.scss';

import { trash } from 'ionicons/icons';
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    AlertButton,
    CreateAnimation,
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonRow
} from '@ionic/react';
import {
    NTWReportActions,
    setItemId,
    SystemMessageSeverity,
    SystemMessageType,
    SystemSelectors
} from '@whanau/domains';

import { CRUDListReports } from './crud-list_Reports';

/**
 * Crud List Item Props
 */
interface CrudListItemProps {
    item: CRUDListReports;
    onRemove?: (id) => void;
    onShowAlert: (header: string, buttons: AlertButton[]) => void;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
const CrudListItemReports: React.FC<CrudListItemProps> = ({ onShowAlert, onRemove, item }) => {
    // Environment
    // Component state hooks
    const dispatch = useDispatch();
    const [hide, setHide] = useState(false);
    const lastMessage = useSelector(SystemSelectors.lastMessage);
    const handleRemove = async (): Promise<void> => {
        onRemove(item.id);
    };
    const fetchReport = () => {
        dispatch(NTWReportActions.fetchReportItem(item.combinedKey));
        dispatch(NTWReportActions.setSubmitted(item.Submitted));
    };
    // Template
    return (
        <CreateAnimation
            duration={250}
            keyframes={[
                { offset: 0, transform: 'translateX(0) scale(1)', opacity: 1 },
                { offset: 0.8, transform: 'translateX(450px) scale(1)', opacity: 0 },
                { offset: 1, transform: 'translateX(450px) scale(0)', opacity: 0 }
            ]}
            afterStyles={{
                display: 'none'
            }}
            play={hide}
        >
            <IonItem
                id={item.combinedKey}
                onClick={fetchReport}
                className={item.Submitted ? 'disableRow' : 'enableRow'}
            >
                <IonGrid className="list">
                    <IonRow>
                        <IonCol size="2">{item.ProgrammeNames}</IonCol>
                        <IonCol size="2">{item.PartnerName}</IonCol>
                        <IonCol size="2">{item.startDate}</IonCol>
                        <IonCol size="2">{item.endDate}</IonCol>
                        <IonCol size="2">{item.combinedKey}</IonCol>
                        <IonCol size="2">{item.Submitted ? 'Submitted' : 'Saved'}</IonCol>
                    </IonRow>
                </IonGrid>
            </IonItem>
        </CreateAnimation>
    );
};

// Exporting memoized component
export default React.memo(CrudListItemReports);
