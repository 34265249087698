import './crud-list-item.scss';

import { trash } from 'ionicons/icons';
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    AlertButton,
    CreateAnimation,
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonRow
} from '@ionic/react';
import {
    NTWReportActions,
    setItemId,
    SystemMessageSeverity,
    SystemMessageType,
    getSubmitted,
    SystemSelectors
} from '@whanau/domains';

import { CRUDListItemTwoColumn } from './crud-list_twoColumn';

/**
 * Crud List Item Props
 */
interface CrudListItemProps {
    item: CRUDListItemTwoColumn;
    onRemove?: (id) => void;
    onShowAlert: (header: string, buttons: AlertButton[]) => void;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
const CrudListItemTwoColumn: React.FC<CrudListItemProps> = ({ onShowAlert, onRemove, item }) => {
    // Environment
    // Component state hooks
    const [hide, setHide] = useState(false);
    const submitted = useSelector(getSubmitted);
    const lastMessage = useSelector(SystemSelectors.lastMessage);
    const [description, setDescription] = useState('');
    const handleRemove = async (): Promise<void> => {
        onRemove(item.id);
    };
    // Component side effects
    // Hide element if it's been deleted successfully
    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            // Make new FileReader
            const reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);
            console.log(file);
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                const baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const onFileChange = async e => {
        const file = e.target.files[0];

        await getBase64(file)
            .then(result => {
                file['base64'] = result;
                const data_pdf = file['base64'].substring(file['base64'].indexOf(',') + 1);
                const healthAttachment = {
                    title: 'Health Attachment',
                    description: 'Health Attachment document',
                    fileName: file.name,
                    fileType: file.type,
                    base64Document: data_pdf
                };
                // setHealthAttachmentData(healthAttachment);
            })
            .catch(err => {
                console.log(err);
            });
    };
    // Template
    return (
        <CreateAnimation
            duration={250}
            keyframes={[
                { offset: 0, transform: 'translateX(0) scale(1)', opacity: 1 },
                { offset: 0.8, transform: 'translateX(450px) scale(1)', opacity: 0 },
                { offset: 1, transform: 'translateX(450px) scale(0)', opacity: 0 }
            ]}
            afterStyles={{
                display: 'none'
            }}
            play={hide}
        >
            <IonItem>
                <IonGrid className="list">
                    <IonRow>
                        <IonCol size="4" style={{ color: '#585656' }}>
                            {item.Provider5}
                        </IonCol>
                        <IonCol size="4" style={{ color: '#585656' }}>
                            {item.tests}
                        </IonCol>
                        <IonCol size="4" style={{ color: '#585656' }}>
                            {item.positive}
                            <span style={{ float: 'right', color: 'red' }}>
                                <IonButton
                                    fill="clear"
                                    onClick={(): Promise<void> => handleRemove()}
                                    disabled={submitted}
                                >
                                    <IonIcon size="small" icon={trash} slot="end" />
                                </IonButton>
                            </span>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonItem>
        </CreateAnimation>
    );
};

// Exporting memoized component
export default React.memo(CrudListItemTwoColumn);
