import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const saveItem = (key: string, created_by: string, item: CONTRACTS.item, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .post('/Save', item, {
            headers: {
                CombinedKey: key,
                CreatedBy: created_by
            }
        })
        .then((response: AxiosResponse<CONTRACTS.item>) => response)
        .catch(error => ({ error }));
};

export const updateItem = (key: string, created_by: string, item: CONTRACTS.item, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .put(`/Update`, item, {
            headers: {
                CombinedKey: key,
                CreatedBy: created_by
            }
        })
        .then((response: AxiosResponse<CONTRACTS.item>) => response)
        .catch(error => ({ error }));
};

export const fetchItem = (key: string, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .get(`/get?id=${key}`, {
            headers: {
                orgName: 'unq4532441b17894794a994ec64bca7e'
            }
        })
        .then((response: AxiosResponse<CONTRACTS.item>) => response)
        .catch(error => ({ error }));
};
export const fetchReports = (created_by: string, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .get(`/GetList?createdby=${created_by}`, {
            headers: {
                CreatedBy: created_by
            }
        })
        .then((response: AxiosResponse<CONTRACTS.item>) => response)
        .catch(error => ({ error }));
};
export const submitItem = (key: string, created_by: string, item: CONTRACTS.item, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .post('/Submit', item, {
            headers: {
                CombinedKey: key,
                CreatedBy: created_by
            }
        })
        .then((response: AxiosResponse<CONTRACTS.item>) => response)
        .catch(error => ({ error }));
};
