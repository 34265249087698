// import './form-control.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react';
import { FieldErrors } from 'react-hook-form';

import { IonInput } from '@ionic/react';

import { FormControl } from '../forms/form-control';

interface nHIFormTextProps {
    name: string;
    control: any;
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
    readonly?: boolean;
    color?: string;
    maxlength?: number;
    placeholder?: string;
    position?: string;
    required?: boolean;
    type?: any;
}

export const KFormText: React.FC<nHIFormTextProps> = ({
    name,
    control,
    label,
    errors,
    borders,
    background,
    readonly = false,
    color,
    maxlength,
    placeholder,
    position,
    required,
    type
}): ReactElement => {
    // Component hooks
    // Component side effects

    // Component private methods
    const changeColor = () => {
        color = 'primary';
    };
    return (
        <FormControl
            name={name}
            control={control}
            label={label}
            position={position}
            render={({ onChange, value }): ReactElement => (
                <IonInput
                    onIonInput={onChange}
                    value={value}
                    onIonChange={changeColor}
                    readonly={readonly}
                    color={color}
                    maxlength={maxlength}
                    placeholder={placeholder}
                    required={required}
                    type={type}
                    clearInput
                />
            )}
            errors={errors}
            borders={borders}
            background={background}
        />
    );
};
