import './section.scss';

import { alertCircle, informationCircle } from 'ionicons/icons';
/* eslint-disable react/prop-types */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StringLocale } from 'yup';

import {
    AlertButton,
    IonAlert,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonRow,
    IonText
} from '@ionic/react';
import { ChildHubActions } from '@whanau/domains';

import { SectionDescriptionText } from './sectionDescrition';

export interface SectionDescription {
    id: string;
    item: string;
}

interface SectionItemProps {
    sectionMainTitle?: string;
    sectionDescriptionItem?: SectionDescription[];
    sectionTitle?: string;
    sectionSubtitle?: string;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
export const Section: React.FC<SectionItemProps> = ({
    sectionMainTitle,
    sectionDescriptionItem,
    sectionTitle,
    sectionSubtitle
}): ReactElement => {
    // Component state hooks (if any needed)
    const dispatch = useDispatch();

    // Template
    return (
        <div className="container">
            {sectionMainTitle && <h1>{sectionMainTitle}</h1>}
            {sectionTitle && <h3>{sectionTitle}</h3>}
            {sectionDescriptionItem && (
                <div className="sectionBox">
                    {sectionSubtitle && <p>{sectionSubtitle}</p>}
                    {sectionDescriptionItem && sectionDescriptionItem.length > 0
                        ? sectionDescriptionItem.map(sectionDescription => (
                              <SectionDescriptionText key={sectionDescription.id} item={sectionDescription.item} />
                          ))
                        : ''}
                </div>
            )}
        </div>
    );
};
