import './home.scss';

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import { getReportList, getReportKey, getSubmitted, NTWReportActions } from '@whanau/domains';
import { CrudListReports } from '../components/crud/crud-list_Reports';
import { KFormText } from '../components/inputs';
// import Child from '../Child Hub Collobarative Kia Kotahi te Tamaiti FINAL-02.png';
import { Section } from '../components/section';
import { setMaxListeners } from 'process';

// import logo1 from '../MDHB Logo Clr 4 Proc.png';

export const MainPage = () => {
    const items = useSelector(getReportList);
    const key = useSelector(getReportKey);
    const submitted = useSelector(getSubmitted);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [list, setList] = useState([]);
    const created_by = sessionStorage.getItem('UserName');
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const page1Title = 'Whānau Ora Covid19 Dashboard';
    const sectionTitle = 'Sharing the collaboration of our network';
    const sectionSubtitle =
        "The Whānau Ora Covid19 Dashboard is linked to the 'Fight for your whakapapa' campaign. It aims to:";
    const descriptionList = [
        {
            id: '1',
            item:
                'Provide our network with regional insights on how Whānau Ora supports whānau Māori and Māori communities alike, deal with the impact of Covid19 and more importantly how we collectively implement strategies locally and across Te Ika a Māui'
        },
        {
            id: '2',
            item: 'Build a library of strategies for operating and living in a Covid19 environment '
        },
        {
            id: '3',
            item: 'Provide accumulative reporting for quarterly funder reporting'
        }
    ];
    const { control, handleSubmit, formState, setError, setValue, errors, reset, getValues } = useForm({
        defaultValues: { ...items }
    });
    useEffect(() => {
        setTimeout(() => {
            dispatch(NTWReportActions.fetchReports(created_by));
        }, 500);
    }, [created_by, dispatch]);
    useEffect(() => {
        if (items.length > 0) {
            setList(
                items.map((item: any, index) => ({
                    id: index,
                    ProgrammeNames: item.ProgrammeNames,
                    PartnerName: item.PartnerName,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    Submitted: item.isSubmitted,
                    combinedKey: item.combinedKey
                }))
            );
        }
    }, [items]);
    const handleNext = (data): void => {
        dispatch(NTWReportActions.setActiveStep(1));
    };
    const createNew = (): void => {
        dispatch(NTWReportActions.setReportKey(''));
        dispatch(NTWReportActions.setReportItem({}));
        dispatch(NTWReportActions.setState1NTWItem({}));
        dispatch(NTWReportActions.setState2NTWItem({}));
        dispatch(NTWReportActions.setState3NTWItem({}));
        dispatch(NTWReportActions.setState4NTWItem({}));
        dispatch(NTWReportActions.setState5NTWItem({}));
        dispatch(NTWReportActions.setState6NTWItem({}));
        dispatch(NTWReportActions.setState7NTWItem({}));
        dispatch(NTWReportActions.setSubmitted(false));
        dispatch(NTWReportActions.setActiveStep(1));
    };
    return (
        <IonGrid>
            <IonRow class="sectionHeading">
                <IonCol size="12">
                    <Section
                        sectionMainTitle={page1Title}
                        sectionDescriptionItem={descriptionList}
                        sectionTitle={sectionTitle}
                        sectionSubtitle={sectionSubtitle}
                    />
                </IonCol>
            </IonRow>
            <IonRow class="row">
                <IonCol size="12" style={{ marginLeft: '50px' }}>
                    <p>
                        We acknowledge that these sections will be fast moving so we are keen to capture this data on a
                        weekly basis. (Due each Monday for the week previous)
                    </p>
                </IonCol>
            </IonRow>
            <IonRow class="row">
                <IonCol size="12">{list && <CrudListReports Items={list} />}</IonCol>
            </IonRow>
            <IonRow className="container">
                <IonCol size="1"></IonCol>
                <IonCol size="8"></IonCol>
                <IonCol size="3" className="buttonSet">
                    <IonButton className="buttonStyleSave" onClick={createNew}>
                        {'Create New'}
                    </IonButton>
                    <IonButton className="buttonStyle" onClick={handleNext} disabled={key === '' || submitted === true}>
                        {'Update'}
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default MainPage;
