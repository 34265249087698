import './radio-group-control.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement, ReactNode } from 'react';

import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';

interface WTRadioGroupControlProps {
    value: any;
    onChange: () => void;
    items: WTRadioItemControlProps[];
}

interface WTRadioItemControlProps {
    value: any;
    label: string | ReactNode;
}

export const RadioGroupControl: React.FC<WTRadioGroupControlProps> = ({ value, onChange, items }): ReactElement => {
    // Component hooks

    // Component private methods

    // Component private props

    return (
        <div className="radio-group-wrapper">
            <IonRadioGroup onIonChange={onChange} value={value} className="row">
                {items &&
                    items.map((item, k) => (
                        <div className="radio-item-wrapper" key={k}>
                            <IonItem lines="none">
                                <IonRadio value={item.value} />
                                <IonLabel className="radioLabel">{item.label}</IonLabel>
                            </IonItem>
                        </div>
                    ))}
            </IonRadioGroup>
        </div>
    );
};
