import './crud-list.scss';

import { info } from 'console';
import { alertCircle, informationCircle } from 'ionicons/icons';
/* eslint-disable react/prop-types */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    AlertButton,
    IonAlert,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonRow,
    IonText
} from '@ionic/react';
import {
    getNavigator,
    getNhi,
    getNhiFilters,
    getNhiList,
    getUserProfile,
    nhiLookupActions,
    SystemSelectors
} from '@whanau/domains';

import CrudListItemNoHeader from './crud-list-item_noHeader';
import { CrudListPagination } from './crud-list-pagination';

export interface CRUDListItemNoHeader {
    Number: number;
    Provider4?: string;
    Location?: string;
    Address?: string;
    Days?: string;
}

/**
 * Crud List Props
 */
interface CRUDListProps {
    Items: CRUDListItemNoHeader[];
    noItemsLabel?: string;
    onRemove?: (id) => void;
}

/**
 * Crud List Item
 * Generic list item for data entities lists
 */
export const CrudListNoHeader: React.FC<CRUDListProps> = ({ Items, onRemove, noItemsLabel }): ReactElement => {
    // Component state hooks (if any needed)
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState('');
    const [alertButtons, setAlertButtons] = useState<(AlertButton | string)[]>([]);
    const [label, setLabel] = useState('');
    // Component private methods
    // Handle alert status and contents
    const handleShowAlert = useCallback((header: string, buttons: AlertButton[]) => {
        setAlertHeader(header);
        setAlertButtons(buttons);
        setShowAlert(true);
    }, []);
    // Remove item
    const handleRemove = async (id: string): Promise<void> => {
        await onRemove(id);
    };
    // Template
    return (
        <>
            <IonGrid className="mainList">
                <IonRow>
                    <IonCol>
                        <IonItem className="patient-list-grid">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="3" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Name of the Provider</IonLabel>
                                    </IonCol>
                                    <IonCol size="3" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Name of Location </IonLabel>
                                    </IonCol>
                                    <IonCol size="3" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Address of Location </IonLabel>
                                    </IonCol>
                                    <IonCol size="3" style={{ backgroundColor: '#585656' }}>
                                        <IonLabel style={{ color: '#fff' }}>Days & Hours</IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                        <IonList className="patient-list">
                            {Items && Items.length > 0
                                ? Items.map((Item, index) => (
                                      <CrudListItemNoHeader
                                          onRemove={handleRemove}
                                          onShowAlert={handleShowAlert}
                                          key={index}
                                          item={Item}
                                      />
                                  ))
                                : ''}
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <IonAlert
                isOpen={showAlert}
                header={alertHeader}
                buttons={alertButtons}
                onDidDismiss={(): void => setShowAlert(false)}
            ></IonAlert>
        </>
    );
};
