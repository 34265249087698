import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State3ActionTypes {
    FetchItem = 'State3/fetchItem',
    SetState3NTWItem = 'State3/setState3NTWItem',
    SetState3_1Item = 'State3/setState3_1Item',
    StoreItem = 'State3/storeItem',
    SetState3Image = 'State3/setState3Image',
    ClearState3Item = 'State3/clearState3Item',
    ClearState3Image = 'State3/clearState3Image'
}
/**
 * Actions
 */
export const State3Actions = {
    setState3Image: (Img): ActionWithPayload<State3ActionTypes.SetState3Image, { Img }> =>
        createAction(State3ActionTypes.SetState3Image, { Img }),
    setState3NTWItem: (Item): ActionWithPayload<State3ActionTypes.SetState3NTWItem, { Item }> =>
        createAction(State3ActionTypes.SetState3NTWItem, { Item }),
    setState3_1Item: (Item): ActionWithPayload<State3ActionTypes.SetState3_1Item, { Item }> =>
        createAction(State3ActionTypes.SetState3_1Item, { Item }),
    clearState3Image: (): Action<State3ActionTypes.ClearState3Image> =>
        createAction(State3ActionTypes.ClearState3Image),
    clearState3Item: (): Action<State3ActionTypes.ClearState3Item> => createAction(State3ActionTypes.ClearState3Item),
    storeItem: (item): ActionWithPayload<State3ActionTypes.StoreItem, { item }> =>
        createAction(State3ActionTypes.StoreItem, item)
};
