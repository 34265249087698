import './form-kaimahi-control.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';

import { IonCol, IonContent, IonItem, IonLabel, IonNote } from '@ionic/react';

interface KFormControlProps {
    name: string;
    control: any;
    render: any;
    label?: string;
    errors?: FieldErrors;
    borders?: boolean;
    background?: boolean;
}

export const CheckboxControl: React.FC<KFormControlProps> = ({
    name,
    control,
    render,
    label,
    errors,
    borders,
    background
}): ReactElement => {
    // Component hooks
    const [error, setError] = useState(null);

    // Component side effects
    useEffect(() => {
        setError((errors && errors[name]) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors[name]]);

    // Component private methods

    return (
        <IonItem lines="none">
            <Controller name={name} control={control} render={render} />
            {error && <IonNote color="danger">{error.message}</IonNote>}
        </IonItem>
    );
};
