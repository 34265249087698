/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.scss';

import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createStore, IModuleStore } from 'redux-dynamic-modules-core';
import { getSagaExtension } from 'redux-dynamic-modules-saga';

import {
    AuthenticationResult,
    EventMessage,
    EventType,
    IPublicClientApplication,
    PublicClientApplication
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
    authActions,
    BaseConfiguration,
    configurationService,
    getAuthModule,
    getNTWReportModule,
    restService,
    storageService
} from '@whanau/domains';

import { msalConfig } from '../authConfig';
import { environment } from '../environments/environment';
import Home from './pages/home';
const ProviderOverride = Provider as any;

const appConfiguration: BaseConfiguration & any = {
    rest: {
        baseURL: environment.api.baseUrl
    }
};
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;

        msalInstance.setActiveAccount(account);
    }
});
class App extends React.Component {
    private _store: IModuleStore<any>;
    private _token: string;
    constructor(props: any, context: any) {
        super(props, context);
        // init Redux modules
        this._store = createStore({ extensions: [getSagaExtension()] }, ...getNTWReportModule());
        // init configuration service
        configurationService.merge(appConfiguration);
        msalInstance
            .handleRedirectPromise()
            .then(authResult => {
                // Check if user signed in
                const account = msalInstance.getActiveAccount();
                account && sessionStorage.setItem('UserName', account.username);
                if (!account) {
                    // redirect anonymous user to login page
                    msalInstance.loginRedirect();
                } else {
                    const accessTokenRequest = {
                        scopes: [],
                        account: account[0]
                    };
                    msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
                        const accessToken = accessTokenResponse.accessToken;
                        accessToken && sessionStorage.setItem('AuthUserToken', accessToken);
                        const authToken = accessToken;
                        this._store.dispatch(authActions.setUserToken(accessToken));
                        restService.setAuthHeaders(authToken);
                    });
                }
            })
            .catch(err => {
                // TODO: Handle errors
                console.log(err);
            });
    }
    render() {
        return (
            <MsalProvider instance={msalInstance}>
                <ProviderOverride store={this._store}>
                    <IonApp>
                        <Home />
                    </IonApp>
                </ProviderOverride>
            </MsalProvider>
        );
    }
}

export default App;
