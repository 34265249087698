/* eslint-disable react/prop-types */
import './progress_bar.scss';
import 'react-step-progress-bar/styles.css';

import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

const MultiStepProgressBar = props => {
    let stepPercentage = 0;

    // eslint-disable-next-line react/prop-types
    if (props.currentStep === 1) {
        stepPercentage = 0;
    } else if (props.currentStep === 2) {
        stepPercentage = 16.67;
    } else if (props.currentStep === 3) {
        stepPercentage = 33.34;
    } else if (props.currentStep === 4) {
        stepPercentage = 50.01;
    } else if (props.currentStep === 5) {
        stepPercentage = 66.68;
    } else if (props.currentStep === 6) {
        stepPercentage = 83.35;
    } else if (props.currentStep === 7) {
        stepPercentage = 100;
    }

    return (
        <ProgressBar percent={stepPercentage} className="progressContianer">
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
                )}
            </Step>
        </ProgressBar>
    );
};

export default MultiStepProgressBar;
