import './select-control.scss';

/* eslint-disable react/prop-types */
import React, { ReactElement, ReactNode } from 'react';

import { IonSelect, IonSelectOption } from '@ionic/react';

interface WTSelectControlProps {
    value: any;
    onChange: () => void;
    items: WTSelectItemControlProps[];
    multiple: boolean;
    disabled: boolean;
    placeholder: string;
}

export interface WTSelectItemControlProps {
    value: any;
    label: string | ReactNode;
}

export const SelectControl: React.FC<WTSelectControlProps> = ({
    value,
    onChange,
    items,
    multiple,
    disabled,
    placeholder
}): ReactElement => {
    // Component hooks

    // Component private methods

    // Component private props

    return (
        <div className="select-control-wrapper">
            <IonSelect
                interface="popover"
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onIonChange={onChange}
            >
                {items &&
                    items.map((item, i) => (
                        <div className="" key={i}>
                            <IonSelectOption value={item.value}>{item.label}</IonSelectOption>
                        </div>
                    ))}
            </IonSelect>
        </div>
    );
};
